<template>
  <v-form @submit.prevent="submitResponse">
    <p class="mb-3">
      A solution is prepared by mixing
      <number-value :value="massAlcohol" unit="\text{g}" />
      of alcohol with
      <latex-number :number="volumeWater" unit="\text{mL}" />
      of water. Assuming that the density of water is 1.0000 g/mL, determine the percentage of
      alcohol in the resulting solution.
    </p>
    <calculation-input
      v-model="inputs.wtPct"
      class="mb-0"
      prepend-text="$\text{Wt }\%$:"
      append-text="$\%$"
    />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'DeterminingWeightPercentOfAlcohol',
  components: {CalculationInput, NumberValue, LatexNumber},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        wtPct: null,
      },
    };
  },
  computed: {
    massAlcohol() {
      return this.taskState.getValueBySymbol('massAlcohol').content;
    },
    volumeWater() {
      return 100 - this.massAlcohol.toFloat();
    },
  },
};
</script>

<style scoped></style>
